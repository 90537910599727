import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import { useEffect } from "react"
import { useState } from "react"
import BlogCard from "../components/blog/blogCard"
import PageNumber from "../components/blog/pageNumber"
import { Breadcrumbs } from "../components/blog/breadcrumbs"
import Seo from "../components/Seo"
import HeroBlog from "../components/blog/hero"
import Navbar from "../components/blog/navbar"
import Search from "../assets/img/blog/Search.svg"
import Layout from "../components/Layout"
import SearchBar from "../components/blog/searchBar"
import MissingSearch from "../components/blog/missingSearch"
const BlogList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  font-family: Work Sans;

  a {
    text-decoration: none;
    cursor: pointer;
  }
  .row {
    display: flex;
    flex-direction: row;
    /* align-content: center; */
    align-items: center;
    justify-content: space-between;
    margin: 16px 0;
    width: 100%;
    max-width: 1028px;
  }

  .resize {
    width: 100%;
    max-width: 1280px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`

const ArticleContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-top: 5%;
  display: flex;
  flex-wrap: wrap;
  gap: 5% 5%;
  padding: 0 5%;
`

const SearchStyle = styled.div`
  margin-top: 32px;
  width: 80%;
  display: flex;
  justify-content: center;

  border-radius: 0;

  input {
    appearance: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    max-width: 480px;
    padding: 4px 8px;
    border: solid rgba(0, 0, 0, 0.25) 1px;
    :focus {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      outline: none;
    }
  }
  .box {
    width: 36px;
    height: 36px;
    border: solid rgba(0, 0, 0, 0.25) 1px;
    cursor: pointer;
  }
`

export default function Blog({ data, pageContext, location }) {
  const posts = data.allPrismicLicensespringPost.nodes
  const [checklocation, setCheckLocation] = useState("/")
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState("")

  let blogNumber = 0

  useEffect(() => {
    let params = new URLSearchParams(document.location.search)
    let page = params.get("page") // is the string "Jonathan"
    console.log("Page: " + page)
    if (page) setPage(page)
  }, [])
  useEffect(() => {
    setCheckLocation(window.location.pathname)
  }, [])

  const resolveSEOTitle = (category) => {
    switch (category.toLowerCase()) {
      case "glossary":
        return "Software Licensing Terminology: LicenseSpring Blog"
      case "guide":
        return "Guides to using the LicenseSpring Service: LicenseSpring Blog"
      case "news":
        return "News and announcements: LicenseSpring Blog"
      case "other":
        return "Uncategorized posts and updates: LicenseSpring Blog"
      case "tutorials":
        return "LicenseSpring implementation tutorials: LicenseSpring Blog"
    }
  }
  return (
    <Layout>
      <BlogList>
        <Seo
          title={resolveSEOTitle(pageContext.category)}
          description={pageContext?.meta_desc}
        />
        <HeroBlog />
        <Navbar categories={pageContext.categoryTypes} />
        <div className="resize">
          <SearchBar setPage={setPage} setSearch={setSearch} />
          <Breadcrumbs
            currentPath={["/"].concat(
              location.pathname.split("/").filter((name) => name !== "")
            )}
          />
          <ArticleContainer>
            {posts.map((post, i) => {
              let author = post.data.author.document?.data.name
                ? post.data.author.document?.data.name
                : {
                    post: {
                      data: {
                        author: { document: { data: { name: "anonymous" } } },
                      },
                    },
                  }
              if (
                post.data.title.toLowerCase().includes(search.toLowerCase()) ||
                post.data.body.text.toLowerCase().includes(search.toLowerCase())
              ) {
                blogNumber += 1

                return (
                  <BlogCard
                    post={post}
                    author={author ? author : "anonymous"}
                    visible={
                      blogNumber - 1 <= 8 * (page - 1) - 1 ||
                      blogNumber - 1 > 8 * page - 1
                        ? "none"
                        : "flex"
                    }
                  />
                )
              }
            })}
            {blogNumber == 0 && <MissingSearch />}
          </ArticleContainer>
          <PageNumber
            location={checklocation}
            blogNumber={blogNumber - 1}
            setPage={setPage}
            page={page}
          />
        </div>
      </BlogList>
    </Layout>
  )
}

export const query = graphql`
  query BlogCategoryQuery($category: String!) {
    allPrismicLicensespringPost(
      sort: { fields: data___publish_date, order: DESC }
      filter: { data: { category: { eq: $category } } }
    ) {
      nodes {
        data {
          url
          title
          fit_image
          thumbnail {
            gatsbyImageData
          }
          body {
            text
          }
          read_time
          publish_date(formatString: "MMMM D, Y")
          last_updated(formatString: "MMMM D, Y")
          category
          category_color
          description
          featured
          author {
            document {
              ... on PrismicAuthor {
                id
                data {
                  name
                }
              }
            }
          }
        }
        uid
      }
    }
  }
`
